import { QueryOptions, useTeamfightQuery } from "~/graphql";
import { findSummonersQuery } from "~/graphql/query/findSummoners/findSummoners.query";
import { SummonerSearchResultType } from "~/types/summoner";

export interface GetSummonerResult {
  findSummoners: {
    total: number;
    results: SummonerSearchResultType[];
  };
}

interface Variables {
  searchTerm: string;
  skip?: number;
  take?: number;
}


export async function findSummoners(variables: Variables, options?: QueryOptions) {
  const result = await useTeamfightQuery<GetSummonerResult, Variables>({
    query: findSummonersQuery,
    variables,
    options
  });

  return {
    ...result,
    data: computed<{
      total: number,
      results: SummonerSearchResultType[]
    }>(() => result.result.value?.findSummoners || { total: 0, results: [] })
  };
}